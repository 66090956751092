import { configureFonts } from 'react-native-paper';
import { DefaultTheme } from 'react-native-paper';
const fontConfig = {
  fontFamily: 'Poppins'
};
const lightTheme = {
  ...DefaultTheme,
  roundness: 5,
  colors: {
      ...DefaultTheme.colors,
      primary: '#12B76A',
      background: '#FFFFFF',
      onBackground: '#F5F5F5',
      onBackgroundDark: '#E9E9E9',
      OnBackgroundDarkBorder: '#E9E9E9',
      blackTxt: '#090D13',
      whiteTxt: '#ffffff',
      text: '#808080',
      secondaryText: '#090D13',
      inputTxtColor: '#000000',
      detailText: '#D4D4D4',
      errorColor: '#B3261E',
      blueColor: '#2970FF',
      placeHolderText: '#4E5574',
      white: '#F4F4F4',
      redColor: '#FF4545',
      orangeColor: '#FFAA0F',
      borderColor: '#1E243D',
      deviderColor: '#151B27',
      disabledIconColor: '#A1A5AD',
      surface: '#ffffff',
      onSurface: '#000000',
      onSurfaceVariant: '#4E5574',
      surfaceVariant: '#4E5574',
      primaryContainer: '#12B76A',
      onPrimaryContainer: '#ffffff',
      accent: '#12B76A',
      secondaryContainer: '#12B76A',
      outlineVariant: '#1E243D',
      Waiting: '#12B76A',
      Staging: '#FFAA10',
      Serving: '#2970FF',
      Completed: '#095b35',
      Removed: '#EF3329',
      Skipped: '#B3261E',
      Exited: '#EF3329',
      Confirmed: '#12B76A',
      Unconfirmed: '#FEFF04',
      Buffer: '#FF8D5C'
  },
  fontWeight: {
    button: 600,
    label: 500
  },
  fontSize: {
    label: 13
  },
  fonts: configureFonts({ config: fontConfig })
};

const darkTheme = {
  ...DefaultTheme,
  roundness: 5,
  colors: {
    ...DefaultTheme.colors,
    primary: '#12B76A',
    background: '#090D13',
    onBackground: '#111523',
    onBackgroundDark: '#111523',
    OnBackgroundDarkBorder: '#1E243D',
    blackTxt: '#090D13',
    whiteTxt: '#ffffff',
    text: '#BAC0CE',
    secondaryText: '#ffffff',
    inputTxtColor: '#ffffff',
    detailText: '#D4D4D4',
    errorColor: '#B3261E',
    blueColor: '#2970FF',
    placeHolderText: '#4E5574',
    white: '#F4F4F4',
    redColor: '#FF4545',
    orangeColor: '#FFAA0F',
    borderColor: '#1E243D',
    deviderColor: '#151B27',
    disabledIconColor: '#A1A5AD',
    surface: '#111523',
    onSurface: '#ffffff',
    onSurfaceVariant: '#ffffff',
    surfaceVariant: '#ffffff',
    primaryContainer: '#12B76A',
    onPrimaryContainer: '#ffffff',
    accent: '#12B76A',
    secondaryContainer: '#12B76A',
    outlineVariant: '#1E243D',
    Waiting: '#12B76A',
    Staging: '#FFAA10',
    Serving: '#576AB3',
    Completed: '#095b35',
    Removed: '#BF2F2D',
    Skipped: '#BF4A56',
    Exited: '#BF2F2D',
    Confirmed: '#12B76A',
    Unconfirmed: '#FEFF04',
    Buffer: '#FF8D5C'
  },
  fontWeight: {
    button: 600,
    label: 500
  },
  fontSize: {
    label: 13
  },
  fonts: configureFonts({ config: fontConfig })
};

export { lightTheme, darkTheme };
