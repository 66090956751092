import 'intl-pluralrules';
import i18n from 'i18next';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { initReactI18next } from 'react-i18next';
import translationBG from './src/assets/locales/bg/translation.json';
import translationEN from './src/assets/locales/en/translation.json';
import translationGJ from './src/assets/locales/gj/translation.json';
import translationHn from './src/assets/locales/hn/translation.json';
import translationKn from './src/assets/locales/kn/translation.json';
import translationMH from './src/assets/locales/mh/translation.json';
import translationTG from './src/assets/locales/tg/translation.json';
import translationTM from './src/assets/locales/tm/translation.json';

const STORE_LANGUAGE_KEY = 'lang';
const languageDetector = {
  type: 'languageDetector',
  async: true,
  init: () => {},
  detect: async function (callback) {
    try {
      await AsyncStorage.getItem(STORE_LANGUAGE_KEY).then((language) => {
        if (language) {
          return callback(language);
        } else {
          return callback('en');
        }
      });
    } catch (error) {
      console.log('Error reading language', error);
    }
  },
  cacheUserLanguage: async function (language) {
    try {
      await AsyncStorage.setItem(STORE_LANGUAGE_KEY, language);
    } catch (error) {
      console.log('language-e');
    }
  }
};

const fallbackLng = ['en'];
const availableLanguages = ['bg', 'en', 'gj', 'hn', 'kn', 'mh', 'tg', 'tm'];

const resources = {
  bg: {
    translation: translationBG
  },
  en: {
    translation: translationEN
  },
  gj: {
    translation: translationGJ
  },
  hn: {
    translation: translationHn
  },
  kn: {
    translation: translationKn
  },
  mh: {
    translation: translationMH
  },
  tg: {
    translation: translationTG
  },
  tm: {
    translation: translationTM
  }
};

i18n
  .use(initReactI18next)
  .use(languageDetector)
  .init({
    resources,
    fallbackLng,
    detection: {
      checkWhitelist: true
    },
    debug: false,
    whitelist: availableLanguages,
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false
    }
  });

export default i18n;
